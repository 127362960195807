import React from 'react';
// import Bus from '../../../../assets/bus.svg';
// import Car from '../../../../assets/car.svg';
import Hotel from '../../../../assets/hotel.png';
import Plane from '../../../../assets/plane.png';
import { SubTitle, Title, TitleHelper } from '../../../../common/Styles/Styles.styles';
import { Section, TextContainer, Button, CardsContainer, Card, CardIcon, CardTitle, ResponsiveButton } from './ExploreSection.styles';
import { BookNowLink } from '../../../../../utils/ServerConfigs';

// Main component
const ExploreSection: React.FC = () => {

  const handleBookNow = () => {
     window.open(BookNowLink, '_blank');
  }

  return (
    <Section>
      <TextContainer>
        <Title>Explore the World <TitleHelper>With YatriPay </TitleHelper> </Title>
        <SubTitle>Book your next travel with YatriPay and see how easy it is.</SubTitle>
        <Button onClick={handleBookNow}>Book Now</Button>
      </TextContainer>
      <CardsContainer>
        <Card>
          <CardIcon src={Hotel} alt="Hotel" />
          <CardTitle>HOTEL</CardTitle>
        </Card>
        <Card>
          <CardIcon src={Plane} alt="Flight" />
          <CardTitle>FLIGHT</CardTitle>
        </Card>
        {/* <Card>
          <CardIcon src={Car} alt="Cab" />
          <CardTitle>CAB</CardTitle>
        </Card>
        <Card>
          <CardIcon src={Bus} alt="Bus" />
          <CardTitle>BUS</CardTitle>
        </Card> */}
      </CardsContainer>
      <ResponsiveButton>Book Now</ResponsiveButton>

    </Section>
  );
};

export default ExploreSection;
