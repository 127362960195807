import React, { useState } from 'react';
import QR from '../../../../assets/QRcode.png';
import { SubTitle, Title, TitleHelper } from '../../../../common/Styles/Styles.styles';
import { HeroSubTitle } from '../../../../../utils/Constants';
import { Container, LeftContainer, Input, SignUpButton, RightContainer, QRCode, InputContainer } from './HeroSection.styles';
import Stats from './Stats';
import { RegisterLink } from '../../../../../utils/ServerConfigs';


const HeroSection = () => {

    const [value, setValue] = useState<string>('');

    const handleSignUp = () => {
        window.open(`${RegisterLink}?value=${value}`, '_blank');
    }

    const handleChange = (e: any) => {
        setValue(e.target.value);
    }

    return (
        <>
            <Container>
                <LeftContainer>
                    <Title>YatriPay: <TitleHelper>One World, One Wallet. 
                    </TitleHelper></Title>
                    <SubTitle>{HeroSubTitle}</SubTitle>
                    <InputContainer>
                        <Input onChange={handleChange} type="text" placeholder="Email/Phone Number" />
                        <SignUpButton onClick={handleSignUp}>Start Now →</SignUpButton>
                    </InputContainer>
                </LeftContainer>
                <RightContainer>
                    <QRCode src={QR} alt='qr' />
                </RightContainer>
            </Container>
            <Stats />
        </>
    );
};

export default HeroSection;
