import styled from "styled-components";


// Styled components
export const Section = styled.section`

  padding: 5% 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  
  padding: 20px;
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;

  }
`;

export const Text = styled.div`

  color: #333;
  font-size: 20px;

  @media (max-width: 1000px) {
    padding-right: 0px;
    text-align: center;
  }
`;


export const ImageWrapper = styled.div`
  
  text-align: center;
  position: relative;

  @media (max-width: 768px) {
    margin: 20px 0;
  }

`;

export const Image = styled.img`
  max-width: 70%;
  height: auto;
`;