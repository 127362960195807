import styled from "styled-components";

export const FooterContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   padding-top: 60px;
   padding-bottom: 20px;
   justify-content: center;
   background-color: #f2f2f2;

`;

export const SubText = styled.div`
   font-size: 20px;
   font-weight: 500;
   line-height: 36px;
   margin-top: 40px;
   line-height: 36px;
   letter-spacing: 0.04em;
   text-align: center;
`;

export const EmailInput = styled.input`
   width: 250px;
   height: 40px;
   border-radius: 80px;
   background-color: transparent;
   border: 1px solid black;
   padding: 0px 15px;
   color: black;
   font-size: 16px;
   line-height: 36px;

   &:focus {
      outline: none;
      border: 1px solid black;
   }
   &::placeholder {
      color: black;
   }
`;

export const SubscribeButton = styled.button`
   background-color: white;
   border: 2px solid white;
   color: #E91E63;
   border-radius: 60px;
   padding: 0px 25px;
   margin-left: 10px;
   cursor: pointer;
   font-size: 16px;
   font-weight: 700;
   line-height: 33px;
`;

export const FooterLinkContainer = styled.div`
   display: flex;
   padding-top: 60px;
   color: black;

   @media (max-width: 850px) {
      flex-direction: column;
      text-align: center;
   }
`;

export const FooterLinks = styled.a`
   font-size: 16px;
   font-weight: 400;
   line-height: 33px;
   letter-spacing: 0.04em;
   cursor: pointer;
   color: black !important;
   text-decoration: none !important;


   @media (max-width: 850px) {
      padding: 5px;
      text-decoration: underline;

   }
`;

export const Divider = styled.span`
   border: 1px solid black;
   margin: 10px;

   @media (max-width: 450px) {
      display: none;

   }
`;

export const InputContainer = styled.div`
   display: flex;
   flex-direction: row; // Default to row, but will change on smaller screens
   padding: 20px;

   @media (max-width: 600px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      button {
         margin-top: 20px;
      }
   }
`;