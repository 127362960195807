import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/common/Header/Header';
import Footer from './components/common/Footer/Footer';
import HeroSection from './components/pages/Home/Section/HeroSection/HeroSection';
import ExploreSection from './components/pages/Home/Section/ExploreSection/ExploreSection';
import TransactionSection from './components/pages/Home/Section/TransactionSection/TransactionSection';
import YVMSection from './components/pages/Home/Section/YVMSection/YVMSection';
import TeamSection from './components/pages/Home/Section/TeamSection/TeamSection';
import MetaVerseSection from './components/pages/Home/Section/MetaVerseSection/MetaVerseSection';
import CareerPage from './components/pages/Career/CareerPage';
import ScrollToHash from './components/common/ScrollToHash'; // Add the ScrollToHash import

function App() {
  return (
    <>
      <Header />
      <ScrollToHash /> {/* Ensure this is placed inside the main component */}
      <Routes>
        {/* Home Page */}
        <Route
          path="/"
          element={
            <>
              <HeroSection />
              <ExploreSection />
              <div id="about">
                <YVMSection />
              </div>
              <TransactionSection />
              <MetaVerseSection />
              <div id="team">
                <TeamSection />
              </div>
            </>
          }
        />
        {/* Careers Page */}
        <Route path="/careers" element={<CareerPage />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
