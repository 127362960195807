import styled from "styled-components";

// Styled components
export const SectionContainer = styled.section`
  text-align: center;
  padding: 5% 5%;
  background: #fff; // Adjust the background as necessary
`;

export const SectionTitle = styled.h2`
  color: #ffd754; // Adjust the color as necessary
  margin-bottom: 30px;
`;

export const TeamList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

export const TeamMemberCard = styled.div`
  width: 250px;
  margin: 10px;
  height: 300px;
  background-color: white;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  padding: 20px 20px;
  border-radius: 8px;

`;

export const MemberImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 1px solid #F1F1F1;
`;

export const LinkedInIcon = styled.a`
  color: #408c72;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  text-decoration: none !important;

  &:hover {
    text-decoration: underline !important;
  }
`;

export const MemberName = styled.div`
  color: #333; // Adjust the color as necessary
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
`;

export const MemberRole = styled.p`
  color: #595C5F; // Adjust the color as necessary
`;

// Responsive media queries
export const ResponsiveTeamList = styled(TeamList)`
  margin-top: 50px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ContentTitle = styled.div`
    font-size: 32px;
    font-weight: 500;
    display: block !important;
    line-height: 30px;
    letter-spacing: 0em;
    padding: 10px;
    text-align: center;
    color: #878787;
    margin-bottom: 30px;

    span {
      color: #408c72;
    }

    @media (max-width: 800px) {
        font-size: 24px;  
    }
`;