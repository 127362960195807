import styled from "styled-components";


export const Section = styled.section`
    display: flex;
    padding: 120px 5%;
    align-items: center;

    @media (max-width: 730px) {
        flex-direction: column;
        justify-content: center;
    }
`;

export const LeftAlignedContent = styled.div`
    max-width: 80%;
    text-align: left;
    flex: 0.9;

    @media (max-width: 600px) {
     text-align: center;
    }
`;

export const RightAlignedContent = styled.div`
  text-align: right;
`;

export const Image = styled.img`
  width: 100%;
  max-width: 300px;

  @media (max-width: 800px) {
    margin-top: 30px;
  
    }
`;

export const Button = styled.a`
    background-color: #408c72;
    color: #fff;
    text-decoration: none !important;
    width: 200px;
    text-align: center;
    height: 50px;
    border: none;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 12px 16px;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    display: flex;

    @media (max-width: 850px) {
        margin: auto;
        display: block;
        font-size: 16px;
    }

    @media (max-width: 730px) {
        margin: auto;
        display: none;
    }

`;

export const ResponsiveButton = styled.a`
    background-color: #408c72;
    color: #fff;
    text-decoration: none !important;
    width: 200px;
    text-align: center;
    height: 50px;
    border: none;
    border-radius: 50px;
    box-sizing: border-box;
    padding: 12px 16px;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    display: none;
    
    @media (max-width: 730px) {
        margin: auto;
        display: block;
        font-size: 16px;
    }

`;

export const ImageContainer = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;