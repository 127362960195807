export const HeroTitle = "One World, One Wallet";

export const HeroSubTitle = "Super fast transactions, tailored for travel and decentralized payments.";

export const AboutYVM = "YatriPay transactions are powered by YatriPay Virtual Machine (YVM), a decentralized blockchain network. Use the YatriPay Mobile App to get on board.";

export const AboutMetaVerse = "YatriPay team is committed to leveraging the capabilities of YVM to explore and expand the possibilities within the Metaverse.";

export const MetaVerseParagraph1 = "The Metaverse has significantly evolved in recent years, gaining substantial attention and traction. Pioneering projects such as Decentraland and Sandbox have revolutionised the concept by enabling users to conduct transactions within these virtual environments using their unique tokens."

export const MetaVerseParagraph2 = "In this rapidly advancing landscape, YatriPay, powered by the YatriPay Virtual Machine (YVM), is poised to make a substantial impact. Our team is committed to leveraging the capabilities of YVM to explore and expand the possibilities within the Metaverse. Our goal is to create a seamless and innovative platform that facilitates various transactions and interactions in these virtual spaces.";

export const MetaVerseParagraph3 = "We are excited to announce that we are on track to unveil the first working prototype of our Metaverse integration by the end of the first quarter of 2024. This milestone will mark a significant step forward in our journey and our contribution to the evolving digital world.";

export const AboutYVM2 = " digitization and decentralized interactions, with seamless DAO compatibility for community-led governance.";

export const TravellerQuest1 = "For international travel, YatriPay facilitates seamless cross-border payments without the high fees and delays often associated with traditional systems. This makes it easier for travellers to pay for services anywhere in the world. This includes booking flights and hotels at present while local transport, and even experiences at the destination are under integration. By using a single platform, travellers can avoid the hassle of currency exchange and manage their expenses more efficiently.";

export const TravellerQuest2 = "For privacy, YatriPay Blockchain's inherent security features mean personal data is better protected in the ecosystem. This is crucial in the travel industry, where sensitive personal and payment information is frequently exchanged.";

export const TravellerQuest3 = "YatriPay looks forward to using Tokens and NFTs for limited access events, exclusive tour packages, or unique souvenirs. NFTs would provide proof of ownership and authenticity, valuable for exclusive experiences. Web3, the resonating future of the internet, is sure to be utilised along Smart Contracts to automate various aspects of travel, from bookings to cancellations and refunds within the YatriPay ecosystem.";

export const MobileDescription = "YatriPay mobile applications provide you a seamless experience of YatriPay's services on your mobile phone. You can download the app from both Apple App Store and Google Play Store. A same account will be used across both Web and Mobile Application.";