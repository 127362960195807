import styled from "styled-components";
//import Robot from '../../../../assets/robot.svg';

// Styled components
export const Container = styled.div`
    display: flex;
    flex-direction: row;
    padding: 90px 5%;

   
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 25%;
    overflow: hidden;

    @media (max-width: 1200px) {
        background-size: 30%;
        background-position: bottom 10% right;
    }

    @media (max-width: 850px) {
        flex-direction: column;
        background-image: unset;
        padding: 5%;
    }

`;

export const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0.95;

    @media (max-width: 1000px) {
        flex: 1;
    }

    @media (max-width: 850px) {
        order: 2;
        justify-content: center;
        flex: unset;
        padding-top: 10%;
    }
`;

export const RightContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: right;
    margin-left: 20px;

    @media (max-width: 850px) {
        order: 1;
        justify-content: center;
    }
`;


export const SignUpButton = styled.button`
    background-color: #408c72;
    color: #fff;
    border: none;
    border-radius: 50px;
    box-sizing: border-box;
    padding: 12px;
    margin-left: 7px;
    font-size: 14px;
    width: 140px;
    cursor: pointer;
    font-weight: 700;
    @media(max-width: 1050px) {
        margin-top: 10px;
        margin-left: unset;
    }
`;

export const QRCode = styled.img`
    width: 300px;
    height: 300px;

    @media(max-width: 550px) {
        width: 200px;
        height: 200px;
    }
`;

export const InputContainer = styled.div`

    @media(max-width: 850px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

`;

export const Input = styled.input`
    border: 1px solid #ccc;
    padding: 10px 10px 10px 15px;
    height: 20px;
    width: 300px;
    border-radius: 50px;

    @media (max-width: 1100px) {
        width: 70%;
    }

    &:focus {
        border: 1px solid #ccc;
        outline: none;
    }
`;

export const WhitePaper = styled.div`
    @media(max-width: 850px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

