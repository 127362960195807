import React, { useState } from "react";
import Chain from "../../../../assets/metaverse.png";
import {
  SubTitle,
  Title,
  TitleHelper,
} from "../../../../common/Styles/Styles.styles";
import {
  AboutMetaVerse,
  MetaVerseParagraph1,
  MetaVerseParagraph2,
  MetaVerseParagraph3,
} from "../../../../../utils/Constants";
import {
  Section,
  Content,
  ImageWrapper,
  Text,
  Image,
  Button
} from "./MetaVerseSection.styles";

const MetaVerseSection: React.FC = () => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <Section>
      <Title style={{ textAlign: "center" }}>
        Crafting<TitleHelper> Tomorrow's Metaverse</TitleHelper>
      </Title>
      <SubTitle style={{ textAlign: "center" }}>
        A Journey Towards Seamless Virtual Transactions
      </SubTitle>
      <Content>
        <ImageWrapper>
          <Image src={Chain} alt="chain" />
        </ImageWrapper>
        <Text>
          {AboutMetaVerse}
          {showMore && (
            <>
              {MetaVerseParagraph1}
              <br />
              <br />
              {MetaVerseParagraph2}
              <br />
              <br />
              {MetaVerseParagraph3}
            </>
          )}
          <Button onClick={toggleShowMore}>
            {showMore ? "Read Less" : "Read More"}
            {showMore ? "▲" : "▼"}
          </Button>
        </Text>
      </Content>
    </Section>
  );
};

export default MetaVerseSection;
