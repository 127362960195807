// Stats.tsx
import React from 'react';
import styled from 'styled-components';

// Styled-components
const StatsContainer = styled.section`
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    background-color: #fff;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: stretch;
    }
`;

const StatBlock = styled.div`
    position: relative;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    // Add vertical lines except for the last StatBlock
    &:not(:first-child)::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 60%; // Adjust the height as needed
        border-left: 1px solid #ccc; // Your desired color for the line
      }
    
      @media (max-width: 768px) {
        padding: 20px 10px;
    
        // Remove vertical lines for mobile view
        &:not(:first-child)::before {
          content: none;
        }
      }
`;

const StatNumber = styled.span`
  font-size: 20px;
  font-weight: bold;
  text-align: left;
`;

const StatDescription = styled.span`
  font-size: 20px;
  text-align: left;
  color: #696984;
`;

interface StatProps {
  number: string;
  description: string;
}

const Stat: React.FC<StatProps> = ({ number, description }) => (
  <StatBlock>
    <StatNumber>{number}</StatNumber>
    <StatDescription>{description}</StatDescription>
  </StatBlock>
);

const Stats: React.FC = () => {
  return (
    <StatsContainer>
      <Stat number="10K+" description="Travel Bookings" />
      <Stat number="1 Million+ " description="YTP Transactions" />
      <Stat number="4 Million+" description="YTP Staked" />
      <Stat number="10K+" description="App Download" />
    </StatsContainer>
  );
};

export default Stats;
