import React from 'react';
import styled from 'styled-components'; // Use styled-components for shiny styling

// Styled Components
const Container = styled.div`
  padding: 50px 10%;
  background: linear-gradient(to right, #f3f3f3, #ffffff);
  color: #333;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  color: #408c72;
  margin-bottom: 10px;
`;

const SubTitle = styled.p`
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 40px;
`;

const JobContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-top: 30px;
`;

const JobCard = styled.div`
  flex: 1 1 calc(45% - 20px);
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  text-align: left;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:hover {
    transform: scale(1.03);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const JobTitle = styled.h2`
  font-size: 1.5rem;
  color: #408c72;
  margin-bottom: 10px;
`;

const JobDetail = styled.p`
  font-size: 1rem;
  margin: 5px 0;
`;

const JobList = styled.ul`
  padding-left: 20px;
  margin-top: 10px;
`;

const Button = styled.a`
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  color: #fff;
  background: #408c72;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background 0.3s ease-in-out;

  &:hover {
    background: #2d8372;
  }
`;

const FooterNote = styled.p`
  margin-top: 50px;
  font-size: 1rem;
  color: #555;
`;

function CareerPage() {
  return (
    <Container>
      <Title>Careers at YatriPay</Title>
      <SubTitle>Welcome to the Career page. Explore opportunities with us!</SubTitle>
      <JobContainer>
        {/* Social Media Marketing Expert */}
        <JobCard>
          <JobTitle>Social Media Marketing Expert</JobTitle>
          <JobDetail><strong>Experience:</strong> Minimum 3 years</JobDetail>
          <JobDetail><strong>Job Description:</strong> We are looking for a creative and results-driven Social Media Marketing Expert to manage and grow our social media presence.</JobDetail>
          <JobList>
            <li>Develop and implement social media strategies.</li>
            <li>Create engaging content for social platforms.</li>
            <li>Analyze campaign performance and optimize strategies.</li>
          </JobList>
        </JobCard>

        {/* Junior React Native Developer */}
        <JobCard>
          <JobTitle>Junior React Native Developer</JobTitle>
          <JobDetail><strong>Experience:</strong> Minimum 2 years</JobDetail>
          <JobDetail><strong>Job Description:</strong> Build and maintain mobile applications using React Native.</JobDetail>
          <JobList>
            <li>Develop and maintain React Native applications.</li>
            <li>Collaborate with backend and design teams.</li>
            <li>Debug and resolve technical issues.</li>
          </JobList>
        </JobCard>

        {/* Junior Python Django Developer */}
        <JobCard>
          <JobTitle>Junior Python Django Developer</JobTitle>
          <JobDetail><strong>Experience:</strong> Minimum 1.5 years</JobDetail>
          <JobDetail><strong>Job Description:</strong> Contribute to backend projects using Django and Python.</JobDetail>
          <JobList>
            <li>Develop and maintain server-side applications.</li>
            <li>Integrate APIs and optimize performance.</li>
            <li>Collaborate with frontend developers.</li>
          </JobList>
        </JobCard>

        {/* Sales Executive */}
        <JobCard>
          <JobTitle>Sales Executive</JobTitle>
          <JobDetail><strong>Experience:</strong> 0 to 1 year</JobDetail>
          <JobDetail><strong>Job Description:</strong> Identify and reach out to potential new users.</JobDetail>
          <JobList>
            <li>Promote YatriPay and its services to potential users.</li>
            <li>Assist them in onboarding and verification.</li>
            <li>Help them understaning and navigating the platform.</li>
          </JobList>
        </JobCard>
      </JobContainer>
      <FooterNote>
        Interested? Please submit your information, and we will get back to you: {' '}
        <a href="https://forms.gle/B1cX9Zwk91F7N8fcA" target="_blank">Application Form</a>.
      </FooterNote>
    </Container>
  );
}

export default CareerPage;
