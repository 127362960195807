export const WalletLink = "https://wallet.yatripay.com/login";

export const LoginLink = "https://wallet.yatripay.com/login";

export const RegisterLink = "https://wallet.yatripay.com/registration-process/step-1";

export const WhitePaperPdfLink = "https://yatripay.com/static/media/YTPWhitePaper.pdf";

export const ExplorerLink = "https://yvmexplorer.yatripay.com/";

export const PlayStoreLink = "https://play.google.com/store/apps/details?id=com.wallet.yatripay";

export const AppleStoreLink = "https://apps.apple.com/app?id=com.wallet.yatripay";

export const BookNowLink = "https://yatripay.in/";